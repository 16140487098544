import { useState, useEffect } from "react";

const isClient = typeof window === "object";

export function useImage(url) {
  const [state, setState] = useState({
    image: undefined,
    loaded: false,
  });

  useEffect(() => {
    if (!isClient) return null;

    const img = document.createElement("img");

    const onLoad = () => {
      setState({ image: img, loaded: true });
    };
    const onError = () => setState({ image: undefined, loaded: true });

    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);

    if (url) {
      img.src = url;
    }

    if (url && img.complete) {
      setState({ image: img, loaded: true });
    }

    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [url]);

  return [state.image, state.loaded];
}
