import { useImage } from "hooks/useImage";
import React from "react";
import { EventItem } from "./EventItem";

export function EventList({ events, isCompact }) {
  return (
    <div className={`event-list`}>
      {events.map((event) => (
        <EventItem key={event.id} event={event} isCompact={isCompact} />
      ))}
    </div>
  );
}
