import { useEffect, useState } from "react";

export function useEvents() {
  const [events, setEvents] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);

    window.fixrApiCallback = (api) => {
      window.fixrApi = api;

      window.fixrApi.onGetTicketsClicked = (event) => {
        window.fixrApi.showCheckout(event.id);
      };

      setEvents(api.events || []);
      setLoaded(true);
    };
  }, []);

  return { events, loaded };
}
