import React, { useMemo, useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { BlackBoxText } from "components/BlackBoxText";
import { Content } from "components/Content";
import TicketShop from "components/TicketShop";
import { List } from "components/List";
import { Page } from "components/Page";
import { useEvents } from "hooks/useEvents";
import { EventList } from "components/EventList";
import { useIsCompact } from "hooks/useIsCompact";

export const query = graphql`
  {
    allSitePage {
      nodes {
        path
        context {
          id
          name
          scriptString
        }
      }
    }
  }
`;

let timeout = null;

const debounce = (callback) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(callback, 200);
};

function IndexPage(p) {
  const { events, loaded } = useEvents();
  const isCompact = useIsCompact(p.location.search);

  const shopId = "0ce5baf5-7624-4e51-b2ab-eb85edc3c9d3";

  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("");

  const filteredEvents = useMemo(() => {
    let nextEvents = events ? [...events] : [];

    if (query) {
      const alphaNumericRegex = new RegExp(/\W/g);
      const testQuery = query.replace(alphaNumericRegex, "").toLowerCase();

      nextEvents = nextEvents.filter((event) => {
        const testEvent = event.name
          .replace(alphaNumericRegex, "")
          .toLowerCase();

        const testAccount = event.salesAccount.name
          .replace(alphaNumericRegex, "")
          .toLowerCase();

        return testEvent.includes(testQuery) || testAccount.includes(testQuery);
      });
    }

    if (sort) {
      const getPrice = (event) => {
        return event.cheapestTicket?.effectivePrice ?? 0;
      };
      if (sort === "priceDesc") {
        nextEvents.sort((a, b) => getPrice(b) - getPrice(a));
      }
      if (sort === "priceAsc") {
        nextEvents.sort((a, b) => getPrice(a) - getPrice(b));
      }
    }

    return nextEvents;
  }, [events, sort, query]);

  return (
    <Page light={false} fixed={false} locationSearch={p.location.search}>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Exeter Students' Guild Events"}
        meta={[
          {
            name: `description`,
            content: "Find out what’s going on at the Students’ Guild",
          },
          {
            property: `og:url`,
            content: `https://events.exeterguild.com${p.location.pathname}`,
          },
          {
            property: `og:title`,
            content: "Exeter Students' Guild Events",
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Content type="home">
        <List>
          <div>
            <BlackBoxText>All events</BlackBoxText>
          </div>
          <div>
            <TicketShop
              scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?baseUrl=https://fixr.co&callback=fixrApiCallback&headless=true&salesChannel=whitelabel-website" data-fixr-shop-id="${shopId}" data-fixr-theme="light"></script>`}
            />
            {!loaded && (
              <div className="duped-fixr-loader">
                <svg viewBox={`0 0 16 16`} xmlns="http://www.w3.org/2000/svg">
                  <circle cx={8} cy={8} r={8} />
                </svg>
                <svg viewBox={`0 0 16 16`} xmlns="http://www.w3.org/2000/svg">
                  <circle cx={8} cy={8} r={8} />
                </svg>
                <svg viewBox={`0 0 16 16`} xmlns="http://www.w3.org/2000/svg">
                  <circle cx={8} cy={8} r={8} />
                </svg>
              </div>
            )}
            {loaded && (
              <React.Fragment>
                {events.length > 0 ? (
                  <>
                    <div className="home__controls">
                      <div className="home__search">
                        <img src="/iconSearch.svg" alt="" />
                        <input
                          onChange={(e) => {
                            const value = e.target.value;
                            const callback = () => setQuery(value || "");
                            debounce(callback);
                          }}
                          placeholder="Search"
                          type="search"
                        />
                      </div>
                      <div className="home__sort">
                        <img src="/iconAngleDown.svg" alt="" />
                        <select
                          onChange={(e) => {
                            const value = e.target.value;
                            setSort(value);
                          }}
                        >
                          <option value="" selected>
                            Date
                          </option>
                          <option value="priceDesc">Price (Highest)</option>
                          <option value="priceAsc">Price (Lowest)</option>
                        </select>
                      </div>
                    </div>
                    {filteredEvents.length > 0 ? (
                      <EventList
                        events={filteredEvents}
                        isCompact={isCompact}
                      />
                    ) : (
                      <p style={{ margin: 0 }}>No events match this search.</p>
                    )}
                  </>
                ) : (
                  <p style={{ margin: 0 }}>No events.</p>
                )}
              </React.Fragment>
            )}
          </div>
        </List>
      </Content>
    </Page>
  );
}

export default IndexPage;
