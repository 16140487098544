import { useImage } from "hooks/useImage";
import React from "react";

function getDateParts(isoString) {
  const date = new Date(isoString);

  return {
    day: date.toLocaleString(undefined, { day: "numeric" }),
    month: date.toLocaleString(undefined, { month: "short" }),
    year: date.toLocaleString(undefined, { year: "numeric" }),
  };
}

function formatMoney(number, currency = "GBP", locale = "GB", options) {
  const opts = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: "symbol",
    currency,
    ...options,
  };

  if (typeof Intl !== "undefined") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      ...opts,
    }).format(number);
  }

  return number;
}

export function EventItem({ event, isCompact }) {
  const [img, loaded] = useImage(event?.eventImages?.[0]);

  const dateParts = event
    ? getDateParts(event.openTimeVenueLocalised)
    : undefined;

  let price = "";

  if (event.cheapestTicket) {
    price = formatMoney(
      event.cheapestTicket.effectivePrice,
      event.cheapestTicket.currency
    );
  }

  function handleView() {
    if (isCompact) {
      window.postMessage({
        message: "fixr.widget:viewEventDetailsClicked",
        event: event,
      });
    } else {
      if (window.fixrApi) {
        window.fixrApi.showEventDetails(event.id);
      } else {
        console.error("no fixr api in global");
      }
    }
  }

  return (
    <div
      role={"button"}
      tabIndex={0}
      onClick={handleView}
      onKeyPress={(e) => {
        if (e.key === " " || e.key === "Enter") {
          handleView();
        }
      }}
      className={`event-item ${loaded ? "event-item--loaded" : ""}`}
    >
      <div className="event-item__image">
        {loaded && <img src={img?.src} alt="" />}
        {dateParts && loaded && (
          <div className="event-item__date-badge">
            <h4>{dateParts.day}</h4>
            <p>{dateParts.month}</p>
          </div>
        )}
      </div>
      <div className="event-item__footer">
        <h4 className="event-item__name">{event?.name}</h4>
        <div className="event-item__content">
          <div className="event-item__venue">{event?.venue?.name}</div>
          <div className="event-item__price">
            {price ? (price === "£0.00" ? "FREE" : price) : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
